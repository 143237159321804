import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calcSwipes',
})
export class CalcSwipesPipe implements PipeTransform {
  transform(col: number, play:boolean = false, loop:boolean = false) {
    const availableCol = 12 - (col || 0);
    const slidesPerView = Math.round((availableCol / 12) * 4);
    const slidesOpts: any = {
      breakpoints: {
        320: {
          slidesPerView: 2.1,
          spaceBetween: 0,
        },
        420: {
          slidesPerView: 3.1,
        },
        768: {
          slidesPerView: slidesPerView,
          // spaceBetween: 0,
        },
      },
      loop: loop,
    };
    if (play) {
      slidesOpts.autoplay = {
        delay: 3000,
        disableOnInteraction: true,
      }
    }
    return slidesOpts;
  }
}
