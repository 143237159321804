import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'query'
})
export class QueryPipe implements PipeTransform {

  transform(product): unknown {
    if(
      product.inscriptionPrice 
      || product.inscriptionPrice === 0 
      || product.inscriptionPrice === null
    ){
      const queryParams = { sbp: 1 };
      return queryParams
    }
     
  }

}
